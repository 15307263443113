import React from "react";
import AboutForm from "../../components/about/AboutForm";
import Footer from "../../components/footer/Footer";
import GetItNow from "../../components/getItNow/GetItNow";
import Header from "../../components/header/Header";
import { useScrollTop } from "../../services/helpingFunc";

export default function AboutUs() {
    useScrollTop()

    return (
        <div>
            <Header title={'About Us'} />

            <AboutForm />

            <GetItNow />

            <Footer />
        </div>
    );
}
