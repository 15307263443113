import React from 'react'
import "./UBProgram.css";
import { UB1, UB2, UB3, UB4, } from '../../assets/images'
import Header from '../../components/header/Header';
import GetItNow from '../../components/getItNow/GetItNow';
import Footer from '../../components/footer/Footer';
import { useScrollTop } from '../../services/helpingFunc';

const ListItem = ({ imageSrc, title, point1, point2, point3, point4, description }) => (
    <div className="UB-list-item">
        <img className="UB-item-image" src={imageSrc} alt={title} />
        <div className='UB-item-content'>
            <h3 className="UB-item-title">{title}</h3>
            <p className="UB-item-description">{description}</p>

            {
                (point1 !== '' && point2 !== '' && point3 !== '' && point4 !== '' && description === '') && (
                    <ul>
                        <li>{point1}</li>
                        <li>{point2}</li>
                        <li>{point3}</li>
                        <li>{point4}</li>
                    </ul>
                )
            }
        </div>
    </div>
);

export default function UBProgram() {
    useScrollTop()

    const items = [
        {
            id: 1,
            imageSrc: UB1,
            title: 'Customized Logistics Solutions',
            description: 'Tailored logistics plans to meet the specific needs of each  business, ensuring efficient and cost-effective shipping routes and methods.',
        },
        {
            id: 2,
            imageSrc: UB2,
            title: 'Local and Global Integration',
            description: 'Empowers users to manage international shipments directly  from their location, simplifying the process of selling and shipping products globally. This  local-global integration facilitates the expansion of business reach, making international markets  more accessible.',
        },
        {
            id: 3,
            imageSrc: UB3,
            title: 'Comprehensive Support and Documentation',
            description: 'Provides full support for customs  documentation, compliance, and regulatory requirements, simplifying the complexities  associated with international trade.',
        },
        {
            id: 4,
            imageSrc: UB4,
            title: 'Partners',
            description: '',
            point1: 'DHL International shipping',
            point2: 'Caribbean ocean shipping company',
            point3: 'InterCaribbean Airway',
            point4: 'Island Bargains'
        },
    ];

    return (
        <div>
            <Header title={'Home'} />

            <div className="UB_main_container">
                <h1 className='UB_top_title'>U.B. Program</h1>
                <h2 className='UB_top_title'> The United Business (U.B.) Program is an innovative ecosystem designed to empower and  unify businesses across a multitude of sectors. By leveraging the capabilities of the SED App,  the program offers a unique platform for local restaurants,  local store / e-commerce, self  employed and large corporations to streamline their operations and expand their market reach  globally.</h2>

                <ul>
                    <li>Comprehensive Account Management</li>
                    <li>Zero-Cost Delivery Services</li>
                    <li>Diverse Employment and Earning Opportunities</li>
                    <li>Enhanced Customer Engagement Tools</li>
                    <li>Sustainability Initiatives</li>
                    <li>Educational Workshops and Seminars</li>
                    <li>Financial Services Integration</li>
                    <li>Global Expansion Support</li>
                    <li>Access to an extensive network for travel bookings, including hotels, flights, and rental  cars, seamlessly integrated into the U.B. Program features.</li>
                    <li>International Shipping and Logistics for E-Commerce</li>
                </ul>

                <div className="UB-list-container">
                    {items.map((item) => (
                        <ListItem
                            key={item.id}
                            imageSrc={item.imageSrc}
                            title={item.title}
                            description={item.description}
                            point1={item.point1}
                            point2={item.point2}
                            point3={item.point3}
                            point4={item.point4}
                        />
                    ))}
                </div>
            </div>

            <GetItNow />

            <Footer />
        </div>
    )
}

