import React from "react";
import FeatureOverview from "../../components/featureOverview/FeatureOverview";
import Footer from "../../components/footer/Footer";
import GetItNow from "../../components/getItNow/GetItNow";
import Header from "../../components/header/Header";
import MainSlider from "../../components/mainSlider/MainSlider";
import Testimonials from "../../components/testimonials/Testimonials";
import { useScrollTop } from "../../services/helpingFunc";

export default function Home() {
    useScrollTop()

    return (
        <div>
            <Header title={'Home'} />

            <MainSlider />

            <FeatureOverview />

            {/* <Testimonials /> */}

            <GetItNow />

            <Footer />
        </div>
    );
}
