import React from "react";
import "./Footer.css";
import {
  AppLogoWhite,
  AndroidDownloadImage,
  IosDownloadImage,
} from "../../assets/images";
import { useNavigate } from "react-router-dom";

export default function Footer() {
  const navigate = useNavigate();

  return (
    <div className="footer_main_container">
      <div className="footer_container">
        <div className="footer_row_view2">
          <img className="logo_image" src={AppLogoWhite} alt="Logo" />
          <h1 className="footer_Text_Below_Logo">
            We can't map out your future, but we can be your co-pilot. We're
            here to equip you with everything you need to soar, to push
            boundaries, and to achieve your wildest dreams.
          </h1>
        </div>

        <div className="footer_row_view">
          <h2>Support</h2>
          <span onClick={() => navigate("/ContactUs")}>Contact Us</span>
        </div>

        <div className="footer_row_view">
          <h2>About SED</h2>
          <span onClick={() => navigate("/AboutUs")}>About Us</span>
        </div>

        <div className="DownloadApp">
          <h2>Download the app</h2>
          <div className="row-image">
            <img
              className="android_download_image"
              src={AndroidDownloadImage}
              alt="android_download_image"
            />
            <img
              className="ios_download_image"
              src={IosDownloadImage}
              alt="ios_download_image"
            />
          </div>
        </div>
      </div>
      <h2 className="footer_Text_Below_Logo">
        All Copyrights Reserved by SED - 2024
      </h2>
    </div>
  );
}
