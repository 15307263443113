import React from "react";
import { Routes, Route } from "react-router-dom";
import * as pages from "../pages";

const Navigation = () => {
  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/DigitalBanking" element={<pages.DigitalBanking />} />
      <Route path="/" element={<pages.Home />} />
      <Route path="/ContactUs" element={<pages.ContactUs />} />
      <Route path="/AboutUs" element={<pages.AboutUs />} />
      <Route path="/UBProgram" element={<pages.UBProgram />} />
      <Route path="/SEDApp" element={<pages.SEDApp />} />
      <Route path="SED-App-Travel" element={<pages.SEDAppTravel />} />
    </Routes>
  );
};

export default Navigation;
