import React from "react";
import places from "../../../assets/images/placesImages.png";
import "../SEDAppTravel.css";

const TravelRewars = () => {
  return (
    <section className="rewardsContainer">
      <h1 className=" heading">SED Travel Rewards</h1>
      <img className=" places" src={places} alt="placesimage" />
      <h2 className="excluciveHeading">
        Exclusive Discounts, Cashback, Rewards, and Points with SED Virtual Card
      </h2>
      <h3 className=" smallHeading">
        Experience the Ultimate in Savings and Rewards with SED App
      </h3>
      <p className="paragraphs">
        At SED App, we believe in rewarding our users for their loyalty and
        trust. By using the SED Virtual Card for your travel bookings and
        everyday expenses, you unlock a world of exclusive benefits designed to
        enhance your overall experience and provide significant savings.
      </p>
      <h2 className="excluciveHeading">Benefits of Using SED Virtual Card:</h2>
      <ul>
        <li>
          <strong>Exclusive Discounts:</strong> Enjoy special discounts on
          hotels, flights, car rentals, and airport taxis when you pay with the
          SED Virtual Card. Our partnerships with leading service providers
          ensure you get the best prices available.
        </li>

        <li>
          <strong>Cashback Offers:</strong> Earn cashback on every transaction
          made with the SED Virtual Card, making your travel and daily expenses
          more affordable. Accumulate cashback rewards that can be used towards
          future purchases.
        </li>
        <li>
          <strong>Reward Points:</strong> Collect reward points with every
          booking and purchase. These points can be redeemed for discounts, free
          services, or exclusive offers within the SED App ecosystem.
        </li>
        <li>
          <strong>Loyalty Programs:</strong> Benefit from our loyalty programs
          that offer additional perks and bonuses based on your spending
          patterns and frequency of use. Enjoy tiered rewards that increase as
          you continue to use the SED Virtual Card.
        </li>
        <li>
          <strong>Secure and Convenient Payments:</strong> The SED Virtual Card
          offers a secure, contactless payment method that ensures your
          financial information is protected. Make payments seamlessly within
          the app or at any vendor that accepts virtual cards.
        </li>
      </ul>
      <h3 className="expHeading">Experience the Best of Travel with SED App</h3>
      <p className="paragraphs">
        Whether you're booking a hotel, flight, car rental, or airport taxi, SED
        App is your ultimate travel companion. Our commitment to providing
        top-notch services and unbeatable convenience ensures that every aspect
        of your trip is covered, allowing you to focus on making memories. With
        SED App, you can travel with confidence, knowing that we have thought of
        everything to make your journey smooth and enjoyable. Book with SED App
        today and discover a world of seamless travel at your fingertips. Take
        advantage of the exclusive benefits of the SED Virtual Card to maximize
        your savings and rewards, ensuring every trip and purchase is more
        rewarding than ever
      </p>
    </section>
  );
};

export default TravelRewars;
