import React from "react";
import {
  HomeIphone1Feature,
  HomeIphone2Feature,
  HomeIphone3Feature,
  HomeIphone4Feature,
  HomeIphone5Feature,
  HomeIphone6Feature,
  HomeIphone7Feature,
  HomeIphone8Feature,
} from "../../assets/images";
import HomeIphone9Featuree from "../../assets/images/HomeIphone9Feature.png";
import HomeIphone10Featuree from "../../assets/images/HomeIphone10Feature.png";

import { ArrowRight } from "../../assets/icons";
import "./FeatureOverview.css";
import { useNavigate } from "react-router-dom";

const ListItem = ({ onpress, imageSrc, title, shortTitle, description }) => (
  <div className="item-cover">
    {imageSrc !== "" && (
      <img className="item-image" src={imageSrc} alt={title} />
    )}
    <div>
      <h3 className="item-title">{title}</h3>
      <h3 className="item-shortTitle">{shortTitle}</h3>
      <p className="item-description">{description}</p>
      <button onClick={onpress} className="blue-button">
        <span>Learn More</span>
        <img src={ArrowRight} alt={"ArrowRight"} />
      </button>
    </div>
  </div>
);

const FeatureOverview = () => {
  const navigate = useNavigate();
  const items = [
    {
      id: 1,
      imageSrc: HomeIphone1Feature,
      title: "SED App",
      shortTitle: "Connect Globally",
      description:
        "Access a world of possibilities across entrepreneurship, freelancing, and more. Expand your reach without borders",
      onpress: () => navigate("/SEDApp"),
    },
    {
      id: 2,
      imageSrc: HomeIphone2Feature,
      title: "SED Digital Banking",
      shortTitle: "Innovative Banking Solutions",
      description:
        "Experience secure, transparent, and easy digital banking that puts financial control in your hands.",
      onpress: () => navigate("/DigitalBanking"),
    },
  ];

  const itemsList = [
    {
      id: 1,
      imageSrc: HomeIphone5Feature,
      title: "SED Products",
      shortTitle: "Elevate Your Experience with Innovation",
      description:
        "Discover cutting-edge solutions that transform everyday tasks into extraordinary experiences.....",
      onpress: () => navigate("/UBProgram"),
    },
    {
      id: 2,
      imageSrc: HomeIphone6Feature,
      title: "Order Food",
      shortTitle: "Savor the Flavor Taste the Love",
      description:
        " Indulge in culinary delights crafted with passion and care. Each bite is a journey of rich flavors........",
      onpress: () => navigate("/UBProgram"),
    },
    {
      id: 3,
      imageSrc: HomeIphone7Feature,
      title: "SED Services",
      shortTitle: "Your Needs Our Expertise",
      description:
        "Experience seamless and professional services tailored to your unique requirements.........",
      onpress: () => navigate("/UBProgram"),
    },
    {
      id: 4,
      imageSrc: HomeIphone8Feature,
      title: "Book Flight",
      shortTitle: "Fly Smart Travel Far",
      description:
        "Embark on your next adventure with confidence and ease. We offer smart travel solutions.......",
      onpress: () => navigate("/UBProgram"),
    },
    {
      id: 5,
      imageSrc: HomeIphone9Featuree,
      title: "Book Hotels",
      shortTitle: "Relax Refresh Reimagine Your Stay",
      description:
        "Discover a haven of comfort and luxury where every detail is designed to enhance your stay....",
      onpress: () => navigate("/SED-App-Travel"),
    },
    {
      id: 6,
      imageSrc: HomeIphone10Featuree,
      title: "Rent A Car",
      shortTitle: "Drive Your Journey Discover Freedom",
      description:
        "Unlock the freedom to explore at your own pace with our reliable and convenient car rental services......",
      onpress: () => navigate("/SED-App-Travel"),
    },
  ];

  return (
    <div className="Main-list-container">
      <h1 className="main_top_title">Feature Overview</h1>
      <div className="list-container">
        {items.map((item) => (
          <ListItem
            key={item.id}
            imageSrc={item.imageSrc}
            title={item.title}
            shortTitle={item.shortTitle}
            description={item.description}
            onpress={item.onpress}
          />
        ))}
      </div>

      <div className="list-container" style={{ alignItems: "center" }}>
        <ListItem
          key={""}
          imageSrc={""}
          title={"U.B. Program"}
          shortTitle={"Empower Your Start-up"}
          description={
            "The United Business (U.B.) Program is an innovative ecosystem designed to empower and  unify businesses across a multitude of sectors. By leveraging the capabilities of the SED App,  the program offers a unique platform for local restaurants, local store / e-commerce, self  employed and large corporations to streamline their operations and expand their market reach  globally."
          }
          onpress={() => navigate("/UBProgram")}
        />
        <img
          className="UBB-item-image"
          src={HomeIphone4Feature}
          alt={"ubb.png"}
        />
      </div>

      <div className="list-container" style={{ marginTop: "50px" }}>
        {itemsList.map((item) => (
          <ListItem
            key={item.id}
            imageSrc={item.imageSrc}
            title={item.title}
            shortTitle={item.shortTitle}
            description={item.description}
            onpress={item.onpress}
          />
        ))}
      </div>
    </div>
  );
};

export default FeatureOverview;
