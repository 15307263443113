import React from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import TravelRewars from "./components/TravelRewars";
import GetItNow from "../../components/getItNow/GetItNow";
import "./SEDAppTravel.css";
import SEDTravel from "./components/SEDTravel";
import Flights from "./components/Flights";
import CarRental from "./components/CarRental";
import AirPortTaxis from "./components/AirPortTaxis";

const SEDAppTravel = () => {
  return (
    <>
      <Header title="SED_App_Travel" />
      <div className="container ">
        <TravelRewars />
        <SEDTravel />
        <Flights />
        <CarRental />
        <AirPortTaxis />
      </div>
      <GetItNow />

      <Footer />
    </>
  );
};

export default SEDAppTravel;
