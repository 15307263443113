import React from "react";
import "./SEDApp.css";
import { SED1, SED2, SED3, SED4, SED5, SED6 } from "../../assets/images";
import Header from "../../components/header/Header";
import GetItNow from "../../components/getItNow/GetItNow";
import Footer from "../../components/footer/Footer";
import { useScrollTop } from "../../services/helpingFunc";

const ListItem = ({ imageSrc, title, shortTitle, description }) => (
  <div className="SED-list-item">
    <img className="SED-item-image" src={imageSrc} alt={title} />
    <div>
      <h3 className="SED-item-title">{title}</h3>
      <p className="SED-item-description">{description}</p>
    </div>
  </div>
);

export default function SEDApp() {
  useScrollTop();

  const items = [
    {
      id: 1,
      imageSrc: SED1,
      title: "Provide Global Opportunities",
      description:
        "From entrepreneurship to freelancing and beyond, the  SED App is a gateway to a world of possibilities, enabling users to find and seize  opportunities for income generation, irrespective of geographical boundaries.",
    },
    {
      id: 2,
      imageSrc: SED2,
      title: "Foster Global Partnerships",
      description:
        "The app serves as a nexus for like-minded individuals,  businesses, and investors worldwide, encouraging the formation of partnerships that  leverage diverse experiences and expertise.",
    },
    {
      id: 3,
      imageSrc: SED3,
      title: "Support Entrepreneurs",
      description:
        "Offering a platform for entrepreneurs to showcase their ideas,  the SED App facilitates connections with potential investors, helping to scale ventures  within a vibrant community.",
    },
    {
      id: 4,
      imageSrc: SED4,
      title: "Facilitate Mentorship",
      description:
        "Access to mentorship from industry veterans and seasoned  professionals provides users with insights, guidance, and the encouragement needed to  accelerate growth.",
    },
    {
      id: 5,
      imageSrc: SED5,
      title: "Celebrate Creativity",
      description:
        "Creatives can share their talents with a global audience, gaining  exposure, building a following, and exploring avenues to monetize their passions.",
    },
    {
      id: 6,
      imageSrc: SED6,
      title: "Ensure Easeof Use and Security",
      description:
        "With a user-friendly interface and robust security  measures, the SED App offers a seamless and safe experience for users to explore  opportunities and forge connections.",
    },
  ];

  return (
    <div>
      <Header title={"Home"} />

      <div className="SED_main_container">
        <h1 className="SED_top_title">Our Platform: The SED App</h1>
        <h2 className="SED_top_subtitle">
          The SED App is the embodiment of our mission and vision—a digital
          ecosystem where success, empowerment, and discovery converge to
          redefine individual journeys towards prosperity. It is designed to:
        </h2>

        <div className="SED-list-container">
          {items.map((item) => (
            <ListItem
              key={item.id}
              imageSrc={item.imageSrc}
              title={item.title}
              shortTitle={item.shortTitle}
              description={item.description}
            />
          ))}
        </div>
      </div>

      <GetItNow />

      <Footer />
    </div>
  );
}
