import "../SEDAppTravel.css";
import flight from "../../../assets/images/Frame 1686555068.png";

const Flights = () => {
  return (
    <section className=" rewardsContainer">
      <h1 className="heading">Flights</h1>
      <img src={flight} alt="airplane" />
      <h2 className="excluciveHeading">
        Fly with Ease: Book Your Flights through SED App
      </h2>
      <p className=" p2">
        Finding the right flight at the right price has never been easier. With
        SED App, you can explore a vast array of flight options from all major
        airlines, ensuring you reach your destination smoothly and comfortably
      </p>
      <h2 className="excluciveHeading">
        Benefits of Booking Flights with SED App:
      </h2>
      <ul>
        <li>
          <strong>Extensive Flight Options:</strong> Choose from a comprehensive
          range of flights, including economy, business, and first-class
          options, catering to all budgets and preferences. We provide flights
          to destinations worldwide, ensuring you can travel wherever you need
          to go
        </li>
        <li>
          <strong>Best Price Guarantee:</strong> Benefit from competitive
          pricing and exclusive discounts, ensuring you get the best deals on
          your flights. Our price comparison tools help you find the most
          affordable options.
        </li>
        <li>
          <strong>Flexible Booking Options:</strong> Enjoy the convenience of
          flexible booking policies, including options for refunds and changes,
          making your travel planning hassle-free. Our platform allows you to
          manage your bookings effortlessly.
        </li>
        <li>
          <stong>Real-Time Updates:</stong> Stay informed with real-time flight
          status updates, ensuring you are always aware of any changes or
          delays. Get notifications directly to your device for a stress-free
          travel experience.
        </li>
        <li>
          <strong>Easy Comparison Tools:</strong> Use our advanced search
          filters and comparison tools to find the perfect flight based on your
          preferences for time, duration, airline, and price. Our intuitive
          interface makes it simple to compare and select the best options.
        </li>
      </ul>
    </section>
  );
};

export default Flights;
