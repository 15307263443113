import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userData: null,
  accessToken: '',
  refreshToken: '',
  fcmToken: '',
};

export const userDataSlice = createSlice({
  name: 'userData',
  initialState,
  reducers: {
    userData: (state, action) => {
      state.userData = action.payload;
    },
    accessToken: (state, action) => {
      state.accessToken = action.payload;
    },
    refreshToken: (state, action) => {
      state.refreshToken = action.payload;
    },
    fcmToken: (state, action) => {
      state.fcmToken = action.payload;
    },
  },
});

export const { userData, accessToken, refreshToken, fcmToken } = userDataSlice.actions;

export default userDataSlice.reducer;
