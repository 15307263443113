import plane from "../../../assets/images/image 1507 (2).png";

const AirPortTaxis = () => {
  return (
    <section className="rewardsContainer">
      <h1 className=" heading">Airport Taxis</h1>
      <img src={plane} alt="plane" />
      <h2 className=" excluciveHeading">
        Effortless Airport Transfers with SED App Airport Taxis
      </h2>
      <p className="p2">
        Start and end your trip on a stress-free note with SED App’s airport
        taxi services. We ensure a smooth, reliable, and comfortable transfer
        between the airport and your destination.
      </p>
      <h2 className=" excluciveHeading">
        Benefits of Booking Airport Taxis with SED App:
      </h2>
      <ul>
        <li>
          <strong>Punctual and Reliable Service:</strong> Count on our airport
          taxis to be on time, every time, ensuring you never miss a flight or
          wait long after landing. Our drivers are committed to providing timely
          and efficient transfers.
        </li>
        <li>
          <strong>Professional Drivers</strong>: Enjoy a safe and comfortable
          ride with our experienced, courteous, and professional drivers. All
          our drivers are thoroughly vetted and trained to offer the highest
          standard of service.
        </li>
        <li>
          <strong>Transparent Pricing:</strong> Benefit from fixed and
          transparent pricing with no hidden charges, so you know exactly what
          you’re paying. Our upfront pricing ensures you can plan your travel
          budget with confidence.
        </li>
        <li>
          <strong>Meet and Greet Service:</strong> Experience a warm welcome
          with our meet and greet service, where your driver will be waiting for
          you at arrivals, ready to assist with your luggage. This personalized
          service ensures a smooth transition from the airport to your
          destination.
        </li>
        <li>
          <strong>Convenient Booking:</strong> Easily book your airport transfer
          in advance through our user-friendly platform, ensuring a hassle-free
          start or end to your journey. Our booking process is quick and
          straightforward, with instant confirmation.
        </li>
      </ul>
    </section>
  );
};

export default AirPortTaxis;
