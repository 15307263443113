import React from 'react'
import './MainSlider.css'
import { SliderImage, SliderImage1 } from '../../assets/images'
import { ArrowRight } from '../../assets/icons'

const ListItem = ({ title, description }) => (
    <div>
        <h3 className="slider-item-title">{title}</h3>
        <h3 className="slider-item-shortTitle">SE<span>D</span></h3>
        <p className="slider-item-description">{description}</p>
        <button className="slider-blue-button">
            <span>Learn More</span>
            <img src={ArrowRight} alt={'Learn More'} />
        </button>
    </div>
);

export default function MainSlider() {
    return (
        <div className='slider-container'>
            <ListItem
                title={'Unlock Your Potential With'}
                description={'Explore global opportunities, foster partnerships, and celebrate creativity'}
            />
            <img className="slider-UB-item-image" src={SliderImage1} alt={''} />
        </div>
    )
}

