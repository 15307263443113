import React from "react";
import "./DigitalBanking.css";
import { Card1 } from "../../assets/images";
import {
  SED1,
  SED2,
  SED3,
  SED4,
  SED5,
  SED6,
  virtualCard1,
  virtualCard2,
  virtualCard3,
  virtualCard4,
  virtualCard5,
} from "../../assets/images";
import Header from "../../components/header/Header";
import GetItNow from "../../components/getItNow/GetItNow";
import Footer from "../../components/footer/Footer";
import { useScrollTop } from "../../services/helpingFunc";

const ListItem = ({ imageSrc }) => (
  <div className="Digital-list-item">
    <img className="Digital-item-image" src={imageSrc} alt={"Card"} />
  </div>
);

const ListItem1 = ({ imageSrc, title, shortTitle, description }) => (
  <div className="SED-list-item">
    <img className="SED-item-image" src={imageSrc} alt={title} />
    <div>
      <h3 className="SED-item-title">{title}</h3>
      <p className="SED-item-description">{description}</p>
    </div>
  </div>
);

export default function DigitalBanking() {
  useScrollTop();

  const items = [
    {
      id: 1,
      imageSrc: Card1,
    },
    {
      id: 2,
      imageSrc: Card1,
    },
    {
      id: 3,
      imageSrc: Card1,
    },
  ];

  const items1 = [
    {
      id: 1,
      imageSrc: virtualCard1,
      title: "Reward Program",
      description:
        "Earn rewards for using the virtual card for transactions exceeding $100 weekly. Accumulate points that can be redeemed for various perks and benefits.",
    },
    {
      id: 2,
      imageSrc: virtualCard2,
      title: "Discount On Travel",
      description: "Enjoy discounts on flights, hotels, and car rentals when you use the SED Virtual Card for your travel bookings.",
    },
    {
      id: 3,
      imageSrc: virtualCard3,
      title: "Exclusive Offers",
      description: "Access exclusive offers and discounts on a wide range of products and services when you make purchases using the virtual card",
    },
    {
      id: 4,
      imageSrc: virtualCard4,
      title: "In-App Advertising Perks",
      description: "Receive special offers or discounts on in-app advertising services when you use the SED Virtual Card for your transactions.",
    },
    {
      id: 5,
      imageSrc: virtualCard5,
      title: "Cashback",
      description: "Get cashback rewards on eligible purchases made with the virtual card, allowing you to save money on your everyday expenses.",
    },
  ];

  return (
    <div>
      <Header title={"Home"} />

      <div className="Digital_main_container">
        <h1 className="Digital_top_title">SED Digital Banking</h1>
        <h2 className="Digital_top_title">
          SED Digital Banking: Redefining Banking for the Digital Age
        </h2>

        <div className="Digital-list-container">
          {items.map((item) => (
            <ListItem key={item.id} imageSrc={item.imageSrc} />
          ))}
        </div>

        <h3 className="Digital_top_title">
          At SED Payments Bank, we are committed to revolutionizing the banking
          experience by offering secure, transparent, and risk-free digital
          banking solutions that put the power of financial management directly
          into the hands of our customers. Our comprehensive suite of services
          is designed to meet the evolving needs of modern consumers, ensuring
          convenience, accessibility, and peace of mind
        </h3>

        <ul>
          <li>Instant Account Opening and Virtual Debit Card</li>
          <li>Physical Visa Virtual Card Option</li>
          <li>Empowering Financial Inclusion</li>
          <li>
            OurVirtual Visa Card initiative is a testament to this commitment
          </li>
          <li>Secure Contactless Payments and Exclusive Benefits</li>
          <li>Global Accessibility with Tokenized Card Option</li>
        </ul>

        <h3 className="virtual-card-main-text">Here are the benefits of using SED virtual card</h3>
        <div className="SED-list-container">
          {items1.map((item) => (
            <ListItem1
              key={item.id}
              imageSrc={item.imageSrc}
              title={item.title}
              //   shortTitle={item.shortTitle}
              description={item.description}
            />
          ))}
        </div>

        <h3 className="Digital_top_title">
          SED Digital Banking represents the future of banking– a future where
          innovation, accessibility, and security converge to empower
          individuals and businesses alike. Join us on this journey towards a
          more connected, inclusive, and prosperous financial landscape.
        </h3>
      </div>

      <GetItNow />

      <Footer />
    </div>
  );
}
