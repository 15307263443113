import React from 'react'
import { BrowserRouter } from "react-router-dom";
import SnackbarProvider from 'react-simple-snackbar'
import Navigation from './navigation/Navigation';
import { ToastContainer } from 'react-toastify';

const App = () => {
  return (
    <BrowserRouter>
      <SnackbarProvider>
        <Navigation />
      </SnackbarProvider>
      <ToastContainer />
    </BrowserRouter>
  )
}

export default App
