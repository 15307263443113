import React from 'react'
import "./AboutForm.css";
import { About1, About2, About3, Philosophy } from '../../assets/images'

const ListItem = ({ imageSrc, title, shortTitle, description }) => (
    <div className="about-list-item">
        <img className="about-item-image" src={imageSrc} alt={title} />
        <div>
            <h3 className="about-item-title">{title}</h3>
            <p className="about-item-description">{description}</p>
        </div>
    </div>
);

export default function AboutForm() {
    const items = [
        {
            id: 1,
            imageSrc: About1,
            title: 'Empowerment through Innovation',
            description: 'At the heart of S.E.D is a profound commitment to  innovation as a tool for empowerment. We develop and implement groundbreaking  technologies and resources aimed at providing individuals with the means to take control  of their futures.',
        },
        {
            id: 2,
            imageSrc: About2,
            title: 'Collaboration and Support:',
            description: 'Understanding that the journey of self-discovery and  empowerment is not one to be undertaken alone, S.E.D fosters a global network of  support. Through our platforms, individuals can connect, collaborate, and grow together,  ensuring that no one is left to face their journey in isolation.',
        },
        {
            id: 3,
            imageSrc: About3,
            title: 'Education and Resource Access',
            description: 'Recognizing the pivotal role of education and  resource availability in personal development, S.E.D prioritizes the democratization of  access to knowledge and tools. We strive to ensure that everyone, regardless of their  background, has the opportunity to learn, grow, and thrive',
        },
    ];

    return (
        <div className="about_main_container">
            <h1 className='about_top_title'>About Us</h1>

            <h1 className='about_shorttitle'>Core Commitments</h1>

            <div className="about-list-container">
                {items.map((item) => (
                    <ListItem
                        key={item.id}
                        imageSrc={item.imageSrc}
                        title={item.title}
                        shortTitle={item.shortTitle}
                        description={item.description}
                    />
                ))}
            </div>

            <div className="about-list-container2">
                <h1>Operational Philosophy</h1>
                <img className="about-philosophy-item-image" src={Philosophy} alt={'title'} />
                <h2>Our operations are inspired by a kingdom philosophy—a belief in building a community that  uplifts, supports, and empowers its members. This ethos is reflected in our commitment to  creating a supportive ecosystem for all users, fostering an environment where every individual  can flourish.</h2>
                <h1>Conclusion</h1>
                <h2>S.E.D Global Organization Inc. is more than a corporate entity; it is a movement towards  creating a world where every individual is empowered to discover their potential and achieve  their dreams. Our comprehensive approach—spanning innovation, collaboration, education, and  support—ensures that we remain at the forefront of fostering empowerment and success. Join us in our journey towards building a more equitable, sustainable, and prosperous world for all.</h2>
            </div>
        </div>
    )
}

