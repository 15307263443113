import { toast } from 'react-toastify';
import { store } from '../../redux/store';
import { api, BASE_URL } from '../../network/Environment';
import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

export const uploadImageOnS3 = async (src) => {
    return new Promise((resolve, reject) => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("authorization", store.getState().userData.accessToken);

            var formdata = new FormData();
            formdata.append("file", src);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(BASE_URL + api.uploadFileToAWS, requestOptions)
                .then(response => response.json())
                .then(result => resolve(result))
                .catch(error => reject(error));
        } catch (error) {
            console.error("Error uploading to S3:", error);
            reject(error);
        }
    });
}

// time format
export const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const remainingSeconds = time % 60;
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');
    return `${formattedMinutes}:${formattedSeconds}`;
};

// toast Notification format
export const notifyToast = (msg) => {
    toast(msg, {
        position: toast.POSITION.BOTTOM_RIGHT
    });
    // toast.success(msg, {
    //     position: toast.POSITION.TOP_RIGHT,
    // });

    // toast.error(msg, {
    //     position: toast.POSITION.TOP_CENTER,
    // });

    // toast.warning(msg, {
    //     position: toast.POSITION.TOP_LEFT,
    // });

    // toast.info(msg, {
    //     position: toast.POSITION.BOTTOM_CENTER,
    // });

    // toast(msg, {
    //     position: toast.POSITION.BOTTOM_RIGHT,
    //     className: "foo-bar",
    // });
};

export const useScrollTop = () => {
    useEffect(() => window.scrollTo({ top: 0, behavior: 'smooth' }), [])
}

export const CreateUUID = () => {
    let deviceId = localStorage.getItem('deviceId')

    if (!deviceId) {
        let id = uuidv4()
        localStorage.setItem('deviceId', id)
        deviceId = id
    }

    return deviceId
}