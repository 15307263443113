import React from 'react'
import './GetItNow.css'
import { AndroidDownloadImage, IosDownloadImage, HomeIphone4, sideImage } from '../../assets/images'

export default function GetItNow() {
    return (
        <div className="get_main_container">
            <img className='sideImage' src={sideImage} alt="sideImage" />

            <div className='get_inner_container'>
                <div className='get_left_container'>
                    <h1>Get it Now</h1>
                    <div className='get_row_image'>
                        <img src={AndroidDownloadImage} alt="android_download_image" />
                        <img src={IosDownloadImage} alt="ios_download_image" />
                    </div>
                </div>

                <div className='get_right_container'>
                    <img src={HomeIphone4} alt="HomeIphone4" />
                </div>
            </div>
        </div>
    )
}

