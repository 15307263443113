// Header.js
import React from "react";
import { AppLogo } from "../../assets/images";
import { menuIcon } from "../../assets/icons";
import "./Header.css"; // Import your CSS file
import { NavLink, useNavigate } from "react-router-dom";

const Header = (props) => {
  const navigate = useNavigate();
  const [isDropdownOpen, setDropdownOpen] = React.useState(false);

  const handleNavigationClick = (option) => {
    console.log(`Clicked on: ${option}`);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  return (
    <>
      <header className="main-header">
        <div className="logo" onClick={() => navigate("/")}>
          <img src={AppLogo} alt="Logo" />
        </div>
        <div className="menuIcon" onClick={toggleDropdown}>
          <img src={menuIcon} alt="Logo" />
        </div>

        <nav className="navigation">
          <ul>
            <li>
              <NavLink
                className={props.title === "Home" && "highlighted"}
                to="/"
                onClick={() => handleNavigationClick("Home")}
              >
                <span className="underline">Home</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                className={props.title === "SED_App_Travel" && "highlighted"}
                to="/SED-App-Travel"
                onClick={() => handleNavigationClick("SED_App_Travel")}
              >
                <span className="underline">SED App - Travel</span>
              </NavLink>
            </li>

            <li>
              <NavLink
                className={props.title === "About Us" && "highlighted"}
                to="/AboutUs"
                onClick={() => handleNavigationClick("About Us")}
              >
                <span className="underline">About</span> <span>Us</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                className={props.title === "Contact Us" && "highlighted"}
                to="/ContactUs"
                onClick={() => handleNavigationClick("Contact Us")}
              >
                <span className="underline">Contact</span> <span>Us</span>
              </NavLink>
            </li>
          </ul>
        </nav>
      </header>
      {isDropdownOpen && (
        <nav className="mobile-navigation">
          <ul>
            <li>
              <NavLink
                className={props.title === "Home" && "highlighted"}
                to="/"
                onClick={() => handleNavigationClick("Home")}
              >
                <span className="underline">Home</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                className={props.title === "SED_App_Travel" && "highlighted"}
                to="/SED-App-Travel"
                onClick={() => handleNavigationClick("SED_App_Travel")}
              >
                <span className="underline">SED App - Travel</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                className={props.title === "About Us" && "highlighted"}
                to="/AboutUs"
                onClick={() => handleNavigationClick("About Us")}
              >
                <span className="underline">About</span> <span>Us</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                className={props.title === "Contact Us" && "highlighted"}
                to="/ContactUs"
                onClick={() => handleNavigationClick("Contact Us")}
              >
                <span className="underline">Contact</span> <span>Us</span>
              </NavLink>
            </li>
          </ul>
        </nav>
      )}
    </>
  );
};

export default Header;
