import cars from "../../../assets/images/image 1507 (1).png";

const CarRental = () => {
  return (
    <section className=" rewardsContainer">
      <h1 className=" heading">Car Rentals</h1>
      <img src={cars} alt="cars" />
      <h2 className=" excluciveHeading">
        Explore Your Destination Freely with SED App Car Rentals
      </h2>
      <p className="p2">
        Having the flexibility to travel at your own pace can transform your
        trip. SED App offers a variety of car rental options to suit your needs,
        whether you're on a family vacation, a business trip, or an adventurous
        road trip.
      </p>
      <h2 className=" excluciveHeading">
        Benefits of Booking Car Rentals with SED App:
      </h2>
      <ul>
        <li>
          <strong>Wide Range of Vehicles:</strong> Choose from an extensive
          selection of vehicles, including compact cars, luxury sedans, SUVs,
          and more, tailored to your travel requirements. Whether you need a car
          for a day or an extended rental, we have you covered.
        </li>
        <li>
          <strong>Affordable Rates:</strong> Take advantage of competitive
          pricing and special offers, ensuring you get the best deal on your
          rental. Our platform offers transparent pricing with no hidden fees.
        </li>
        <li>
          <strong>Convenient Pickup and Drop-off Locations:</strong> Enjoy the
          flexibility of numerous rental locations, including airports and city
          centers, making it easy to pick up and drop off your vehicle. Our
          extensive network ensures you have convenient options wherever you
          are.
        </li>
        <li>
          <strong>Comprehensive Insurance Options:</strong> Travel with peace of
          mind with our comprehensive insurance coverage options, protecting you
          against unforeseen circumstances. Choose the level of coverage that
          suits your needs for a worry-free rental experience.
        </li>
        <li>
          <strong>24/7 Roadside Assistance:</strong> Drive confidently knowing
          that help is just a call away with our 24/7 roadside assistance
          service. Our support team is always ready to assist you in case of any
          issues on the road.
        </li>
      </ul>
    </section>
  );
};

export default CarRental;
