import React from 'react'
import './textInput.css'

const TextInput = (props) => {
    return (
        <div className='input_container' style={props.style} >
            <p>{props.title}*</p>
            <div className='input_container_input' style={props.inputStyle}>
                {
                    props?.textArea ? (
                        <textarea
                            value={props.value}
                            onChange={props.onChange}
                            className="custom-textarea" // Apply the custom CSS class
                            placeholder={props.placeholder}
                        />
                    )
                        : (
                            <input disabled={props.disabled} style={props.inputStyle} onKeyPress={props.onKeyPress} onChange={props.onChange} value={props.value} type={props.type} placeholder={props.placeholder} />
                        )
                }
            </div>
            {props?.errorText ?
                <h5>{props.errorText}</h5>
                :
                null
            }
        </div>
    )
}

export default TextInput
