import React, { useState } from 'react'
import TextInput from '../textInput/TextInput';
import "./ContactForm.css";

export default function ContactForm() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [about, setAbout] = useState('');

    return (
        <div className="contact_main_container">
            <h1 className='contact_top_title'>Contact Us</h1>
            <div className='contact_inner_container'>
                <h1 className='contact_form_title'>Submit your concern</h1>
                <TextInput value={name} onChange={(e) => setName(e.target.value)} placeholder={'EX:Mark odama'} title={'Name'} />
                <TextInput value={email} onChange={(e) => setEmail(e.target.value)} placeholder={'EX:Markoadama@gmail.com'} title={'Email'} />
                <TextInput value={subject} onChange={(e) => setSubject(e.target.value)} placeholder={'EX:project complain'} title={'Subject'} />
                <TextInput textArea value={about} onChange={(e) => setAbout(e.target.value)} placeholder={'About'} title={'About'} />
                <button className="custom-button">Submit</button>
            </div>
        </div>
    )
}

