import React from "react";
import "../SEDAppTravel.css";
import wimage from "../../../assets/images/image 1506.png";

const SEDTravel = () => {
  return (
    <section className="rewardsContainer">
      <h1 className="heading">SED App - Travel</h1>
      <img src={wimage} alt="wimage" />
      <h2 className="excluciveHeading">
        Discover Unmatched Comfort and Convenience with SED App Hotel Bookings
      </h2>
      <p className=" p2">
        At SED App, we understand that where you stay is as important as your
        destination. Our platform offers a seamless hotel booking experience
        that connects you with thousands of top-rated accommodations worldwide,
        ensuring you find the perfect place to stay tailored to your needs and
        budget. Whether you're traveling for business or leisure, SED App is
        your gateway to exceptional hospitality.
      </p>
      <h2 className="excluciveHeading">
        Benefits of booking hotels with SED App
      </h2>
      <ul>
        <li>
          <strong>Wide Selection:</strong> Access an extensive range of hotels,
          from luxurious resorts and boutique hotels to budget-friendly
          accommodations, ensuring you find the ideal stay for your trip.
        </li>
        <li>
          <strong>Competitive Pricing:</strong> Enjoy exclusive deals and
          discounts, ensuring you get the best value for your money without
          compromising on comfort and quality
        </li>
        <li>
          <strong>Detailed Descriptions and Reviews:</strong> Make informed
          decisions with comprehensive hotel descriptions, high-quality images,
          and verified user reviews. Our platform provides all the information
          you need to choose the perfect accommodation
        </li>
        <li>
          <strong>Seamless Booking Process:</strong> Our user-friendly interface
          makes it easy to search, compare, and book hotels in just a few
          clicks. With real-time availability and instant confirmation, your
          booking process is quick and hassle-free.
        </li>
        <li>
          <strong>24/7 Customer Support:</strong> Travel with peace of mind
          knowing that our dedicated support team is available around the clock
          to assist with any queries or issues, ensuring a smooth and enjoyable
          stay.
        </li>
      </ul>
    </section>
  );
};

export default SEDTravel;
