import React from "react";
import ContactForm from "../../components/contactForm/ContactForm";
import Footer from "../../components/footer/Footer";
import GetItNow from "../../components/getItNow/GetItNow";
import Header from "../../components/header/Header";
import { useScrollTop } from "../../services/helpingFunc";

export default function ContactUs() {
    useScrollTop()

    return (
        <div>
            <Header title={'Contact Us'} />

            <ContactForm />

            <GetItNow />

            <Footer />
        </div>
    );
}
